@import 'lib';
@import 'common';
@import 'variables';
@import 'mixins';

body {
    .ck-body-wrapper {
        .ck-body {
            .ck-balloon-panel {
                background: cssvar(cards-and-blocks);
                border-radius: 4px;
                border-color: cssvar(border-and-dividers);
                box-shadow: cssvar(dropdown-box-shadow);

                &:before {
                    border-color: cssvar(border-and-dividers) transparent transparent;
                }

                &:after {
                    border-color: cssvar(cards-and-blocks) transparent transparent transparent;
                }

                .ck-tooltip__text {
                    color: cssvar(primary-text);
                }

                .ck-button {
                    border: none;
                    box-shadow: none !important;
                    border-radius: 4px;

                    padding: 0 8px;

                    font-size: 12px !important;
                    cursor: pointer;
                    color: cssvar(primary-text);

                    &:hover {
                        background: cssvar(brand-12);
                    }

                    &:active {
                        background: initial;
                    }

                    &-save {
                        color: cssvar(success-base);
                    }

                    &-cancel {
                        color: cssvar(error-base);
                    }

                    .ck-button__label {
                        color: cssvar(brand-base);
                    }
                }

                .ck-input {
                    color: cssvar(primary-text);
                    background: cssvar(cards-and-blocks);
                    border-radius: 4px;
                    border-color: cssvar(border-and-dividers);
                    box-shadow: none;

                    &:hover {
                        border-color: cssvar(brand-base);
                    }

                    &:focus:not(:hover) {
                        box-shadow: 0 0 0 $control-focus-border-size cssvar(brand-20);
                    }
                }

                .ck-label {
                    color: cssvar(disable);
                }
            }
        }
    }
}

.font-12px {
    font-size: 12px !important;
}

.font-14px {
    font-size: 14px !important;
}

.font-15px {
    font-size: 15px !important;
}

.font-16px {
    font-size: 16px !important;
}

.font-19px {
    font-size: 19px !important;
}

.font-20px {
    font-size: 20px !important;
}

.font-22px {
    font-size: 22px !important;
}

.font-24px {
    font-size: 24px !important;
}

.font-40px {
    font-size: 40px !important;
}

.mt-2px {
    margin-top: 2px !important;
}

.mb-2px {
    margin-bottom: 2px !important;
}

.ml-2px {
    margin-left: 2px !important;
}

.mr-2px {
    margin-right: 2px !important;
}

.my-2px {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
}

.mx-2px {
    margin-left: 2px !important;
    margin-right: 2px !important;
}

.m-2px {
    margin: 2px !important;
}

.mt-4px {
    margin-top: 4px !important;
}

.mb-4px {
    margin-bottom: 4px !important;
}

.ml-4px {
    margin-left: 4px !important;
}

.mr-4px {
    margin-right: 4px !important;
}

.my-4px {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
}

.mx-4px {
    margin-left: 4px !important;
    margin-right: 4px !important;
}

.m-4px {
    margin: 4px !important;
}

.ml-7px {
    margin-left: 7px !important;
}

.mt-8px {
    margin-top: 8px !important;
}

.mb-8px {
    margin-bottom: 8px !important;
}

.ml-8px {
    margin-left: 8px !important;
}

.mr-8px {
    margin-right: 8px !important;
}

.mx-8px {
    margin-left: 8px !important;
    margin-right: 8px !important;
}

.my-8px {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
}

.m-8px {
    margin: 8px !important;
}

.mt-10px {
    margin-top: 10px !important;
}

.mb-10px {
    margin-bottom: 10px !important;
}

.ml-10px {
    margin-left: 10px !important;
}

.mr-10px {
    margin-right: 10px !important;
}

.mx-10px {
    margin-left: 10px !important;
    margin-right: 10px !important;
}

.my-10px {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.m-10px {
    margin: 10px !important;
}

.mt-16px {
    margin-top: 16px !important;
}

.mb-16px {
    margin-bottom: 16px !important;
}

.ml-16px {
    margin-left: 16px !important;
}

.mr-16px {
    margin-right: 16px !important;
}

.mx-16px {
    margin-left: 16px !important;
    margin-right: 16px !important;
}

.my-16px {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
}

.m-16px {
    margin: 16px !important;
}

.mt-24px {
    margin-top: 24px !important;
}

.mb-24px {
    margin-bottom: 24px !important;
}

.ml-24px {
    margin-left: 24px !important;
}

.mr-24px {
    margin-right: 24px !important;
}

.mx-24px {
    margin-left: 24px !important;
    margin-right: 24px !important;
}

.my-24px {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
}

.m-24px {
    margin: 24px !important;
}

.mt-40px {
    margin-top: 40px !important;
}

.mb-40px {
    margin-bottom: 40px !important;
}

.ml-40px {
    margin-left: 40px !important;
}

.mr-40px {
    margin-right: 40px !important;
}

.py-4px {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
}

.px-8px {
    padding-left: 8px !important;
    padding-right: 8px !important;
}

.py-8px {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

.pt-16px {
    padding-top: 16px !important;
}

.pb-16px {
    padding-bottom: 16px !important;
}

.py-16px {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
}

.px-24px {
    padding-left: 24px !important;
    padding-right: 24px !important;
}

.py-24px {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
}

.p-24px {
    padding: 24px !important;
}

.line-height-12px {
    line-height: 12px !important;
}

.line-height-14px {
    line-height: 14px !important;
}

.line-height-16px {
    line-height: 16px !important;
}

.line-height-18px {
    line-height: 18px !important;
}

.line-height-20px {
    line-height: 20px !important;
}

.line-height-40px {
    line-height: 40px !important;
}

.min-width-80px {
    min-width: 80px;
}

.min-width-120px {
    min-width: 120px;
}

.error-color {
    color: cssvar(error-base) !important;
}

.modal-w400 {
    max-width: 440px !important;
}
