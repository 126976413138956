@import 'variables';
@import 'mixins';

.dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: cssvar(border);
}

.page-header {
    padding: 24px;
    background: cssvar(cards-and-blocks);
    box-shadow: cssvar(soft-default-shadow);
    overflow: hidden;
}

.access-badge-private {
    color: cssvar(white);
    background-color: cssvar(brand-base);
}

.access-badge-public {
    color: cssvar(secondary-text-and-icons);
    background-color: cssvar(border-and-dividers);
}

// asset-version-input
.asset-version-input,
.asset-version-input.size-s,
.asset-version-input.size-m {
    .main-asset-version-input {
        padding: 8px 7px;
    }
}

.asset-version-input.size-s {
    font-size: 12px;
    line-height: 16px;

    .main-asset-version-input {
        height: 24px;

        &.focus {
            &:not(.invalid):not(:hover):not(.hover):not(:active) {
                height: #{24px + 2 *
                    ($control-focus-border-size - $control-normal-border-size)};
            }
        }

        &:active,
        &:hover,
        &.hover {
            height: 24px;
        }
    }
}

.asset-version-input,
.asset-version-input.size-m,
.asset-version-input.size-l {
    font-size: 14px;
    line-height: 18px;
}

.asset-version-input,
.asset-version-input.size-m {
    .main-asset-version-input {
        height: 32px;
        padding: 15px 8px;
    }
}

.asset-version-input.size-l {
    .main-asset-version-input {
        height: 48px;
        padding: 16px 15px;

        &.focus {
            &:not(.invalid):not(:hover):not(.hover):not(:active) {
                height: #{48px + 2 *
                    ($control-focus-border-size - $control-normal-border-size)};
            }
        }

        &:active,
        &:hover,
        &.hover {
            height: 48px;
        }
    }
}
