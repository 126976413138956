// Components import
@import 'components/graph.component.scss';
@import 'components/dashboard.component.scss';
@import 'components/ckeditor.component.scss';
@import 'components/builder.component.scss';

@import './variables';
@import './mixins';

.text-muted,
.app-notification__meta {
    color: cssvar(primary-text) !important;
}

.dynamic-cards {
    margin-bottom: 5px;
    margin-right: 5px;
}

.top-colored-tab {
    .nav-tabs {
        .nav-link {
            border-radius: 0;
            border-bottom: 1px solid cssvar(border-and-dividers);
            font-weight: bold;
            color: cssvar(secondary-text-and-icons);

            &:hover,
            &.active {
                border-top: 1px solid cssvar(brand-base);
                color: cssvar(brand-base);
                &:focus {
                    border-color: cssvar(border-and-dividers) !important;
                    border-top: 1px solid cssvar(brand-base) !important;
                }
            }
        }
    }
}

.main-container-projects {
    height: calc(100vh - #{$default-header-height});
    overflow-x: hidden !important;
    overflow-y: auto !important;

    .breadcrumb {
        display: none;
    }
}

/* Error pages */
.bootstrap-error {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 320px;
    background: url(/assets/images/warning_laptop.png) center 0 no-repeat;
    padding-top: 190px;
    text-align: center;
    font-size: 20px;
    color: cssvar(brand-base);

    .btn {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath d='M11.9531 2.6543L12.0391 4.4375C11.1582 3.40625 9.35352 2.54688 8 2.54688H7.97852C5.37891 2.54688 3.20898 4.41602 2.75781 6.88672C2.75781 6.9082 2.75781 6.92969 2.75781 6.92969C2.75781 7.08008 2.86523 7.1875 3.01562 7.1875H4.06836C4.17578 7.1875 4.2832 7.10156 4.30469 6.99414C4.69141 5.4043 6.3457 4.11523 7.97852 4.11523C9.0957 4.11523 10.4922 4.8457 11.1152 5.76953L8.94531 5.66211H8.92383C8.79492 5.66211 8.66602 5.79102 8.66602 5.91992V6.95117C8.66602 7.08008 8.79492 7.20898 8.92383 7.20898H13.2422C13.3711 7.20898 13.5 7.08008 13.5 6.95117V2.63281C13.5 2.50391 13.3711 2.375 13.2422 2.375H12.2109C12.082 2.39648 11.9531 2.50391 11.9531 2.63281C11.9531 2.6543 11.9531 2.6543 11.9531 2.6543ZM7.97852 11.6562C6.86133 11.6562 5.46484 10.9258 4.8418 10.002L7.0332 10.1094H7.05469C7.18359 10.1094 7.3125 9.98047 7.3125 9.85156V8.82031C7.3125 8.69141 7.18359 8.5625 7.05469 8.5625H2.75781C2.60742 8.5625 2.5 8.69141 2.5 8.82031V13.1172C2.5 13.2676 2.60742 13.375 2.75781 13.375H3.76758C3.89648 13.375 4.02539 13.2676 4.02539 13.1172L3.93945 11.334C4.82031 12.3652 6.625 13.2031 7.97852 13.2031C10.5781 13.2031 12.748 11.3555 13.2207 8.88477C13.2207 8.86328 13.2207 8.8418 13.2207 8.82031C13.2207 8.69141 13.1133 8.5625 12.9629 8.5625H11.9102C11.8027 8.58398 11.6953 8.66992 11.6523 8.77734C11.2656 10.3672 9.63281 11.6562 7.97852 11.6562Z' fill='white'/%3E%3C/svg%3E%0A");
        padding-left: 30px;
        background-position: 8px center;
        background-repeat: no-repeat;
        cursor: pointer;
    }
}

.error-page-template {
    width: 512px;
    margin: 0 auto;

    .server-error,
    .not-found,
    .access-denied {
        display: block;
        width: 100%;
        height: 250px;
    }

    .server-error {
        background: url('/assets/images/server-error.png') center center no-repeat;
        background-size: 50%;
    }

    .not-found {
        background: url('/assets/images/404.png') center center no-repeat;
        background-size: 50%;
    }

    .access-denied {
        background: url('/assets/images/access-denied.jpg') center center no-repeat;
        background-size: 40%;
    }

    p {
        font-size: 16px;
        font-weight: bold;
        color: cssvar(secondary-text-and-icons);
        text-align: center;
    }
}

/* Min width classes */

.min-width-80px {
    min-width: 80px;
}

/* TSM page */
.tsm-template {
    border: 0;
    background: transparent;

    .card-body {
        padding-left: 0;
        padding-right: 0;
    }
}

/* Other customizations */
.positioned-over-the-table {
    bottom: -42px;
}

.full-width-wrapper {
    margin-left: -30px;
    margin-top: -15px;
    margin-right: -30px;
}

.widgets-empty {
    padding: 80px 0;
}

.extend-horizontal-padding {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
}

.compress-vertical-padding {
    padding-top: 0.15em;
    padding-bottom: 0.15em;
}

.extend-horizontal-padding,
.compress-vertical-padding {
    font-size: 12px;
}

.scroll-wrapper {
    height: 510px;
    overflow: auto;
}

rect.highcharts-button-box {
    fill: $white !important;
}

/* Drag and Drop CSS */
.display-list {
    width: 500px;
    max-width: 100%;
    min-height: 60px;
    display: block;
    background: white;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.drag-list {
    max-height: 380px;
    list-style-type: none;
    padding-left: 0;
    overflow-y: auto;

    li {
        display: flex;
        border: 0.75px solid cssvar(border-and-dividers);
        color: cssvar(primary-text);
        border-radius: 5px;
        padding: 5px;
        margin: 0.25rem 0;
        height: 40px;
        cursor: move;
        overflow: visible;

        .drag-thumbnail {
            width: 15%;

            img {
                width: 65%;
                color: #f2f2f2;
                background: #f2f2f2;
            }
        }

        .drag-details {
            flex-basis: 75%;
            line-height: 2.5;
        }

        .drag-options {
            flex-basis: 25%;

            .btn {
                display: none;
            }
        }

        .drag-handle {
            width: 15px;
            line-height: 2;
            color: #b3b3b3;
        }

        &:hover {
            background: cssvar(brand-12);
            border: 1px solid cssvar(brand-base);
            box-shadow: cssvar(card-hover-box-shadow);

            .drag-options {
                .btn {
                    display: block;
                }
            }
        }
    }
}

.cdk-drag-placeholder {
    opacity: 0.9;
    display: flex;
    background: #ffffff;
    border: 1px solid cssvar(brand-base);
    box-shadow: 0 0 8px 2px cssvar(brand-base);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    padding: 5px;
    height: 40px;

    .drag-thumbnail {
        width: 15%;

        img {
            width: 65%;
            color: #f2f2f2;
            background: #f2f2f2;
        }
    }

    .drag-details {
        flex-basis: 75%;
        line-height: 2.5;
    }

    .drag-options {
        flex-basis: 25%;

        .btn {
            display: none;
        }
    }

    .drag-handle {
        width: 15px;
        line-height: 2;
        color: #b3b3b3;
    }
}

/* Animate items as they're being sorted. */
.drag-list.cdk-drop-list-dragging li:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drop-list-dragging .cdk-drag {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-preview {
    display: none;
}

.cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.error-info-block {
    padding-top: 8.5rem;
    padding-bottom: 7rem;

    .error-info-message {
        font-size: 18px;
        padding-top: 0.25rem;
    }
}

.CodeMirror-lint-tooltip {
    z-index: 1060;
}

.notification-placeholder-right-panel {
    .modal-dialog {
        width: 300px;
        padding: 0;
        margin: 0 0 0 auto;
        height: 100%;

        .modal-content {
            border: none;
            border-radius: 0;
            height: 100%;
        }
    }
}

.edit-job-definition-modal {
    .modal-dialog {
        min-width: 1024px;
    }

    .modal-content {
        overflow: inherit;
    }
}

button:not(.btn):focus {
    outline: none;
}

.card-reset {
    .card {
        margin-bottom: 10px;
        box-shadow: 0 0 4px -3px #000;

        &:first-of-type,
        &:not(:first-of-type):not(:last-of-type) {
            border-bottom: 1px solid rgba(0, 0, 0, 0.125);
        }

        &:last-of-type,
        &:first-of-type,
        &:not(:first-of-type):not(:last-of-type) {
            border-radius: 8px;
        }

        .card-header {
            background: transparent;
            border-bottom: 0;
            padding: 8px 20px;
        }
    }

    .inner-card-reset {
        > .card {
            box-shadow: none;
            border: 0 !important;
            margin-bottom: 0;

            .card-header {
                padding: 0;
            }

            .card-body {
                padding: 0 1.25rem;
            }
        }

        .accordion-panel-header {
            padding-left: 20px;
        }
    }
}

.project-build-details {
    .card {
        border: 0;

        .card-header {
            background: none;
        }
    }

    .inner-card-reset {
        &.accordion {
            .card {
                border-bottom: 0 !important;

                .card-header {
                    padding: 0.75rem 1.25rem;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
                }

                .card-body {
                    padding-top: 0;

                    .component-pipeline th {
                        padding: 0 10px !important;
                    }
                }
            }
        }
    }
}

.success-color {
    color: $color-4-hover !important;
}

.failed-color {
    color: $color-0-normal !important;
}

.context-help-stage {
    position: relative;
    z-index: 1033;

    &:not(.btn) {
        background: #fff;
    }
}

.relation-button {
    &.follower {
        min-width: 63px;
    }

    &.contributor {
        min-width: 86px;
    }
}

.not-found-text {
    font-size: 14px;
    color: cssvar(brand-base);
    margin: -25px 0 30px;
}

.not-found-image {
    width: 185px;
    height: 150px;
    background: url('/assets/images/not-found.svg') center center no-repeat;
    background-size: 50%;
}

.counter-badge {
    font-size: 10px;
    width: 30px;
    height: 20px;
    line-height: 20px;
    border-radius: 10px;
}

.warning-text {
    color: cssvar(warning-base);
}

.count-notification {
    vertical-align: middle;
    display: inline-block;
    border-radius: 50%;
    text-align: center;
    background: $color-1-normal;
    color: #ffffff;
    margin-left: 5px;
    padding: 1px 4px;
    font-size: 8px;
}

.wrapper-max-height-100vh-50 {
    max-height: calc(100vh - 50px);
}

.wrapper-max-height-100vh-61 {
    max-height: calc(100vh - 61px);
}

.wrapper-max-height-100vh-75 {
    max-height: calc(100vh - 75px);
}

.wrapper-max-height-100vh-110 {
    max-height: calc(100vh - 110px);
}

.wrapper-max-height-100vh-150 {
    max-height: calc(100vh - 150px);
}

.wrapper-max-height-100vh-180 {
    max-height: calc(100vh - 180px);
}

.wrapper-max-height-100vh-210 {
    max-height: calc(100vh - 210px);
}

.wrapper-max-height-100vh-250 {
    max-height: calc(100vh - 250px);
}

.wrapper-max-height-100vh-310 {
    max-height: calc(100vh - 310px);
}

.wrapper-max-height-100vh-320 {
    max-height: calc(100vh - 320px);
}

.wrapper-max-height-100vh-350 {
    max-height: calc(100vh - 350px);
}

.wrapper-max-height-100vh-380 {
    max-height: calc(100vh - 380px);
}

.wrapper-max-height-100vh-385 {
    max-height: calc(100vh - 385px);
}

.wrapper-max-height-100vh-420 {
    max-height: calc(100vh - 420px);
}

.wrapper-max-height-100vh-450 {
    max-height: calc(100vh - 450px);
}

// EO-7730

@media (min-width: 1600px) {
    .font-12px {
        font-size: 11px;
    }
    .font-18px {
        font-size: 16px;
    }
}

span.ag-header-icon {
    &.ag-header-cell-menu-button {
        transition: none !important;
    }
}

.CodeMirror-hint-active,
.hint-active .completion-details {
    display: block;
}

.sortable {
    cursor: pointer;
}

.sortable::after {
    margin-left: 5px;
    content: '\f107';
    font: normal normal normal 16px/1 'FontAwesome', sans-serif;
}

.sort-desc-active::after {
    margin-left: 5px;
    content: '\f106';
    font: normal normal normal 16px/1 'FontAwesome', sans-serif;
    color: cssvar(brand-base);
}

.sort-asc-active::after {
    margin-left: 5px;
    content: '\f107';
    font: normal normal normal 16px/1 'FontAwesome', sans-serif;
    color: cssvar(brand-base);
}

.thumbnail {
    min-width: 22px;
    width: 22px;
    height: 22px;
    line-height: 22px;
    text-align: center;
    border-radius: 100%;
    display: inline-block;
    vertical-align: middle;
    margin-right: 15px;
    background: cssvar(brand-base);
    color: cssvar(cards-and-blocks);
    margin-top: auto;
    margin-bottom: auto;

    img {
        max-width: 9px;
    }
}

.control-container {
    display: flex !important;
    flex-wrap: wrap !important;
    align-items: center !important;
    margin-left: auto;

    .search {
        width: 300px;
        max-width: 88vw;
    }
}

@media (max-width: 660px) {
    .control-container {
        margin-left: 0;
        justify-content: flex-start !important;
    }
}

.text-secondary {
    color: cssvar(secondary-text-and-icons) !important;
}

$collapsed-sidenav: 48px;
.wrapper-max-width-with-collapsed-sidenav {
    max-width: calc(100vw - #{$collapsed-sidenav});
}

@keyframes opacity-animation {
    0% {
        display: none;
        opacity: 0;
    }

    10% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
}
