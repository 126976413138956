/* You can add global styles to this file, and also import other style files */
//@import './ui-kit';
@import 'node_modules/@dagility-ui/kit/styles/styles';
@import './rtl';
@import './responsive';
@import './common';

@import 'ngx-toastr/toastr.css';

@import 'node_modules/@ag-grid-community/core/dist/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin';
@import 'node_modules/@ag-grid-community/core/dist/styles/ag-grid';
@import 'node_modules/@angular/cdk/overlay-prebuilt';

@media print {
    .breadcrumb {
        display: none !important;
    }
}

.help-content-section {
    .blog-full--body {
        h2,
        h3 {
            font-size: 1.25rem;
            margin-bottom: 25px;
            color: #666;
        }
    }
}

.ag-theme-alpine {
    @include ag-theme-alpine();
}
